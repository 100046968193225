<template>
  <v-app-bar
    id="app-bar"
    class="nav-bar"
    absolute
    app
    color="transparent"
    flat
    height="75"
  >
    <v-btn class="mr-3" elevation="1" fab small @click="setDrawer(!drawer)">
      <v-icon v-if="value"> mdi-view-quilt </v-icon>

      <v-icon v-else> mdi-dots-vertical </v-icon>
    </v-btn>

    <div v-if="showStyleNumber === true || $route.name === routes.COLLECTION">
      <span>
        <v-toolbar-title
          class="style_number hidden-sm-and-down font-weight-light nav-title"
          @click="goToCollectionsList()"
          >All Collections <v-icon>mdi-chevron-right</v-icon></v-toolbar-title
        >
      </span>
    </div>

    <div v-if="showStyleNumber === true">
      <span>
        <v-toolbar-title
          class="style_number hidden-sm-and-down font-weight-light nav-title"
          @click="viewCollection()"
          >Collection <v-icon>mdi-chevron-right</v-icon></v-toolbar-title
        >
      </span>
    </div>

    <div v-if="showStyleNumber === true">
      <span>
        <v-toolbar-title
          class="style_number hidden-sm-and-down font-weight-light nav-title"
          @click="viewInquiry()"
          >Style <v-icon>mdi-chevron-right</v-icon></v-toolbar-title
        >
      </span>
    </div>

    <div v-if="$route.name === routes.INQUIRY && $route.params.id !== 'new'">
      <span>
        <v-toolbar-title
          class="style_number hidden-sm-and-down font-weight-light nav-title"
          @click="goToCollectionsList()"
          >All Collections <v-icon>mdi-chevron-right</v-icon></v-toolbar-title
        >
      </span>
    </div>

    <div v-if="$route.name === routes.INQUIRY && $route.params.id !== 'new'">
      <span>
        <v-toolbar-title
          class="style_number hidden-sm-and-down font-weight-light nav-title"
          @click="viewCollection()"
          >Collection <v-icon>mdi-chevron-right</v-icon></v-toolbar-title
        >
      </span>
    </div>

    <v-toolbar-title
      class="ml-1 hidden-sm-and-down font-weight-light nav-title"
      v-text="
        isBrand() && $route.name === routes.FACTORY_PROFILE
          ? 'Store Profile'
          : $route.name === routes.INQUIRY
          ? 'Style'
          : $route.name
      "
    />

    <v-spacer />

    <div
      v-if="
        $route.name === 'Inquiry' &&
        $route.params.id !== 'new' &&
        this.$store.state.inquiryVerificationStatus.hasPermissions
      "
      class="verification-status-container"
    >
      <v-chip class="ma-2 text-uppercase" :class="verificationStatusClass">
        {{
          this.$store.state.inquiryVerificationStatus.verified
            ? "verified"
            : "unverified"
        }}
      </v-chip>
      <div
        v-if="
          !$store.state.inquiryVerificationStatus.verified &&
          $store.state.inquiryVerificationStatus.requestable
        "
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <div>
              <span v-bind="attrs" v-on="on">
                <div
                  class="request-verification-label"
                  :style="
                    $store.state.inquiryVerificationStatus.requested
                      ? 'color: #ff5252;'
                      : 'color: #501d5f;'
                  "
                  @click="requestOrRemoveInquiryVerification"
                >
                  {{
                    $store.state.inquiryVerificationStatus.requested
                      ? "Remove verification request"
                      : "Request verification"
                  }}
                </div>
              </span>
            </div>
          </template>
          <div class="hover-container">
            <span
              >The brand own this style is not registered in the platform.
              {{
                $store.state.inquiryVerificationStatus.requested
                  ? "Remove verification request sent to Seamless Source"
                  : "Request verification from Seamless Source"
              }}.</span
            >
          </div>
        </v-tooltip>
      </div>
    </div>
    <!-- <v-chip
      v-if="
        $route.name === 'Inquiry' &&
          $route.params.id !== 'new' &&
          (isOwner || isAssignedAccountManager)
      "
      class="ma-2 text-uppercase"
      :class="statusClass"
    >
      {{ this.$store.state.inquiryStatus }}
    </v-chip> -->
    <v-spacer />

    <!-- Search
    <v-text-field :label="$t('search')" color="secondary" hide-details style="max-width: 165px;">
      <template v-if="$vuetify.breakpoint.mdAndUp" v-slot:append-outer>
        <v-btn class="mt-n2" elevation="1" fab small>
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </template>
    </v-text-field>
    -->

    <div class="mx-3" />
    <div
      v-if="$route.name === routes.INQUIRY && $route.params.id !== 'new'"
      class="mx-3 ma-2"
      style="
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        cursor: pointer;
      "
      @click="viewCollection()"
    >
      <v-icon style="color: #442d65; font-size: 22px"
        >mdi-comment-outline</v-icon
      >
      <v-badge v-if="model.chatCount" color="#70D0CE" offset-x="10" bottom>
        <template v-slot:badge>
          <span style="color: #473068; font-weight: bold">{{
            model.chatCount
          }}</span>
        </template>
      </v-badge>
    </div>

    <v-chip
      style="background: rgba(71, 48, 104, 0.1)"
      v-if="$route.name === routes.INQUIRY && $route.params.id !== 'new'"
      class="ma-2"
    >
      <p class="mt-4 style-number">
        {{ model.styleNumber }}
      </p>
    </v-chip>

    <v-chip
      style="background: rgba(71, 48, 104, 0.1)"
      v-if="$route.name === routes.COLLECTION && $route.params.id !== 'new'"
      class="ma-2"
    >
      <p class="mt-4 style-number">
        {{ $store.state.updateCollectionInAppBar.collectionNumber }}
      </p>
    </v-chip>

    <v-chip
      style="background: rgba(71, 48, 104, 0.1)"
      v-if="$route.name === routes.INQUIRY && $route.params.id !== 'new'"
      class="ma-2"
    >
      <p class="mt-4 style-number">
        <truncate-hovering
          :text="
            $store.state.updateStyleInAppBar.styleName != null
              ? this.$store.state.updateStyleInAppBar.styleName
              : model.styleName
          "
          :length="15"
        />
      </p>
    </v-chip>

    <v-btn class="ml-2" min-width="0" text to="/app">
      <dashboard-icon />
    </v-btn>

    <!-- Notifications -->
    <v-menu
      class="sendToFront"
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
          class="ml-2"
          min-width="0"
          text
          v-bind="attrs"
          v-on="on"
          @click="resetNotificationsNumber"
        >
          <notification-bell />
          <v-badge
            color="#70D0CE"
            offset-x="10"
            bottom
            v-if="notificationsNumber"
          >
            <template v-slot:badge>
              <span style="color: #473068; font-weight: bold">{{
                notificationsNumber
              }}</span>
            </template>
          </v-badge>
        </v-btn>
      </template>

      <v-list :tile="false" nav>
        <div class="notification-list-container">
          <div
            class="notification-list-header"
            v-if="notifications.find((n) => n.status == 0)"
          >
            <v-btn @click="MarkAsReadAll()" class="read-status-btn"
              >Mark all as read</v-btn
            >
          </div>
          <app-bar-item
            v-for="(n, i) in notifications"
            :key="`item-${i}`"
            :href="n.data.url"
            :class="
              n.status
                ? 'read-class notification-msg'
                : 'unread-class notification-msg'
            "
          >
            <span @click="notificationOpened(n.id)">
              <truncate-hovering :text="n.data.message" :length="115" />
            </span>
          </app-bar-item>
          <infinite-loading @infinite="fetchMore"></infinite-loading>
        </div>
      </v-list>
    </v-menu>

    <v-menu
      class="sendToFront"
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
          class="ml-2"
          min-width="0"
          style="padding: 0px !important"
          text
          md-menu-trigger
          id="profileButton"
          v-bind="attrs"
          v-on="on"
        >
          <v-avatar size="40">
            <v-img :src="profileImage" />
          </v-avatar>
        </v-btn>
      </template>

      <v-list :tile="false" nav>
        <div class="profile-actions-container">
          <div>
            <app-bar-item class="user-menu__item userProfile">
              <v-list-item-title
                class="profile-actions-item"
                @click="viewProfile"
              >
                <profile-settings-icon />
                &nbsp;&nbsp; {{ currentUser }}
              </v-list-item-title>
            </app-bar-item>
          </div>
          <div v-if="!$userPermissions.getIsSuperAdmin()">
            <app-bar-item class="user-menu__item viewProfile">
              <v-list-item-title
                class="profile-actions-item"
                @click="accountProfile"
              >
                <v-icon size="25" color="#473068">mdi-domain</v-icon>
                &nbsp;&nbsp;&nbsp;
                <v-toolbar-title
                  class="style_number hidden-sm-and-down font-weight-light"
                  ><truncate-hovering
                    :text="userOrganization"
                    :length="15" /></v-toolbar-title
              ></v-list-item-title>
            </app-bar-item>
          </div>
          <a href="#/app/activity_log" style="text-decoration: none">
            <app-bar-item class="user-menu__item actvityLog">
              <v-list-item-title class="profile-actions-item">
                <activity-log />
                &nbsp;&nbsp;&nbsp; Activity Log</v-list-item-title
              >
            </app-bar-item>
          </a>
          <a
            href="#/app/feedback_form"
            v-if="!isSuperAdmin"
            style="text-decoration: none"
          >
            <app-bar-item class="user-menu__item leaveFeedback">
              <v-list-item-title class="profile-actions-item">
                <feedback-icon />
                &nbsp;&nbsp;&nbsp; Leave Feedback</v-list-item-title
              >
            </app-bar-item>
          </a>
          <a href="#/app/feedbacks" v-else style="text-decoration: none">
            <app-bar-item class="user-menu__item leaveFeedback">
              <v-list-item-title class="profile-actions-item">
                <feedback-icon />
                &nbsp;&nbsp;&nbsp; View Feedback</v-list-item-title
              >
            </app-bar-item>
          </a>
          <div @click="logout()">
            <app-bar-item class="user-menu__item logoutButton">
              <v-list-item-title class="profile-actions-item">
                <logout-icon />
                &nbsp;&nbsp;&nbsp; Logout</v-list-item-title
              >
            </app-bar-item>
          </div>
        </div>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
/* eslint-disable */
// Components
import { VHover, VListItem } from "vuetify/lib";
import InfiniteLoading from "vue-infinite-loading";
// Utilities
import { mapState, mapMutations } from "vuex";
import auth from "../../../../auth";
import restAdapter from "../../../../restAdapter";
import store from "@/store";
import { confirmBox, resetLeaveAlert } from "../../../../utils/confirmBox";
import defaultIcon from "../../../../assets/person.webp";
import routes from "../../../../router/routes";
import notification from "../../../../notification";

export default {
  name: "DashboardCoreAppBar",

  async mounted() {
    if (!this.$userPermissions.getIsSuperAdmin()) {
      this.getDashboardDetails();
      this.userOrganization = auth.user.account.organization_registry.name;
      store.commit(
        "SET_CITY_AND_COUNTRY_IS_FILLED",
        !!auth.user.account.city && !!auth.user.account.country
      );
    }
    this.inquiryId = this.$route.params.id;
    this.inquiryId && this.getInquiry(this.inquiryId);

    if (
      this.$route.name === "Tech Pack Development" ||
      this.$route.name === "Fabric Sourcing Form"
    ) {
      this.showStyleNumber = true;
    }

    this.currentUser = auth.user.firstName + " " + auth.user.lastName;

    restAdapter
      .getWithOutCache("/api/user/image_show/" + auth.user.id)
      .then((res) => {
        if (res.data.image) {
          this.profileImage = res.data.image;
        } else {
          this.profileImage = auth.user.avatar
            ? auth.user.avatar
            : this.profileImage;
        }
      });

    this.updateAuthUser();
    this.getNotifications();

    Echo.private("App.Models.User." + auth.user.id).listen(
      ".Illuminate\\Notifications\\Events\\BroadcastNotificationCreated",
      (notification) => {
        this.notifications.unshift({
          id: notification.id,
          data: notification,
          status: 0,
        });
        this.notifications = this.notifications.slice(0, 10);
        this.notificationsNumber++;
        const popsound = new Audio(
          require("../../../../assets/notification.mp3")
        );
        popsound.load();
        popsound.play();
      }
    );
  },

  components: {
    AppBarItem: {
      render(h) {
        return h(VHover, {
          scopedSlots: {
            default: ({ hover }) => {
              return h(
                VListItem,
                {
                  attrs: this.$attrs,
                  class: {
                    "black--text": !hover,
                    notification_hover_color: hover,
                  },
                  props: {
                    activeClass: "",
                    dark: hover,
                    link: true,
                    ...this.$attrs,
                  },
                },
                this.$slots.default
              );
            },
          },
        });
      },
    },
    InfiniteLoading,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    notifications: [],
    currentPage: 1,
    notificationsNumber: 0,
    currentUser: null,
    inquiryId: null,
    showStyleNumber: false,
    profileImage: defaultIcon,
    routes: routes,
    allRead: false,
    userOrganization: null,

    model: {
      id: null,
      styleNumber: null,
      styleName: null,
      inquiryStatus: "active",
      createdBy: null,
      collectionName: null,
      collectionId: null,
      chatCount: 0,
    },
  }),

  watch: {
    updateHeader: {
      handler: function (value) {
        if (value) {
          this.getInquiry(this.$route.params.id);
          store.commit("SET_UPDATE_INQUIRY_IN_APP_BAR", false);
        }
      },
    },
  },
  computed: {
    ...mapState(["drawer"]),
    showStatus() {
      return null;
    },
    isOwner() {
      return this.model && this.model.createdBy === auth.user.id;
    },
    isAssignedAccountManager() {
      return this.$store.state.accountManagers.find(
        (manager) => manager.id === auth.user.id
      );
    },
    isSuperAdmin() {
      return auth.user.role.name === "super_admin";
    },
    verificationStatusClass() {
      const status = this.$store.state.inquiryVerificationStatus.verified
        ? "verified"
        : "unverified";
      return "inquiry_" + status + "_color";
    },
    statusClass() {
      return this.$store.state.inquiryStatus + "_color";
    },
    updateHeader() {
      return this.$store.state.updateInquiryInAppBar;
    },
  },

  filters: {
    truncate: function (text, stop, clamp) {
      return text.slice(0, stop) + (stop < text.length ? clamp || "..." : "");
    },
  },

  methods: {
    ...mapMutations({
      setDrawer: "SET_DRAWER",
    }),

    goToCollectionsList() {
      this.$router.push({ name: routes.COLLECTION_LIST });
    },

    getDashboardDetails() {
      restAdapter.get(`/api/dashboard_details`).then((response) => {
        store.commit("SET_COLLECTION_COUNTS", response.data);
      });
    },

    isBrand() {
      return (
        auth.user &&
        auth.user.account &&
        auth.user.account.organization_registry &&
        auth.user.account.organization_registry.type_details &&
        auth.user.account.organization_registry.type_details.short_code ===
          "brand"
      );
    },

    getInquiry(id) {
      if (this.$route.name !== "Inquiry") {
        return;
      }
      restAdapter.get("/api/inquiries/" + id).then((response) => {
        const inquiry = response.data.inquiry;
        this.model.id = inquiry.id;
        this.model.styleNumber = inquiry.style_number;
        this.model.styleName = inquiry.style_name;
        this.model.inquiryStatus = inquiry.status;
        this.model.createdBy = inquiry.created_by;
        this.model.collectionName = inquiry.collection_name;
        this.model.collectionId = inquiry.collection_id;
        restAdapter
          .get(`/api/collections/${inquiry.collection_id}/unread_count`)
          .then((response) => {
            this.model.chatCount = response.data.unread_count;
          });
        store.commit("SET_INQUIRY_STATUS", this.model.inquiryStatus);
      });
    },

    viewProfile() {
      this.$router.push({ name: "User Profile" });
    },
    accountProfile() {
      this.$router.push({ name: "Account Profile" });
    },
    logout() {
      const leaveGranted = confirmBox(this.$store.state.routeLeaveAlert);
      if (!leaveGranted) {
        return;
      }
      resetLeaveAlert();
      auth.logout().then((response) => {
        this.$router.push({ name: "Login" });
      });
    },
    viewInquiry() {
      this.$router.push({ name: "Inquiry" });
    },
    viewCollection() {
      this.$router.push({
        name: "Collection",
        params: { id: this.model.collectionId },
      });
    },
    async resetNotificationsNumber() {
      this.notificationsNumber = 0;
      await restAdapter.post("/api/mark_activity_log_as_read");
    },

    async getNotifications() {
      this.notifications = [];
      const response = await restAdapter.get("/api/view_activity_log");
      const activityLog = response.data.activityLog.data;
      const unreadCount = response.data.unreadCount;
      activityLog.forEach((notification) => {
        this.notifications.push({
          id: notification.id,
          data: notification.data,
          status: notification.read,
        });
      });
      this.notificationsNumber = unreadCount;
    },

    fetchMore($state) {
      restAdapter
        .get(`/api/view_activity_log?page=${this.currentPage + 1}`)
        .then((res) => {
          if (res.data.activityLog.data.length) {
            const activityLog = res.data.activityLog.data;
            activityLog.forEach((notification) => {
              this.notifications.push({
                id: notification.id,
                data: notification.data,
                status: notification.read,
              });
            });
            this.currentPage++;
            $state.loaded();
          } else {
            $state.complete();
          }
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.status === 400 &&
            error.response.data &&
            error.response.data.error
          ) {
            notification.errors(error.response.data.error);
          } else {
            notification.errors(
              "An error occurred while fetching notifications"
            );
          }
        });
    },

    async notificationOpened(msgId) {
      await restAdapter.post("/api/mark_read_message/" + msgId);
    },

    MarkAsReadAll() {
      restAdapter.post("/api/mark_all_read_messages").then((res) => {
        this.notificationsNumber = 0;
        this.getNotifications();
      });
    },

    requestOrRemoveInquiryVerification() {
      const newStatus = !this.$store.state.inquiryVerificationStatus.requested;
      restAdapter
        .put(`/api/inquiries/${this.$route.params.id}/request_verification`, {
          status: newStatus,
        })
        .then(() => {
          notification.success(
            newStatus
              ? "Verification requested"
              : "Verification request removed"
          );
          store.commit("SET_INQUIRY_VERIFICATION_STATUS", {
            ...this.$store.state.inquiryVerificationStatus,
            requested: newStatus,
          });
        });
    },

    updateAuthUser() {
      restAdapter.get("/api/user/details").then((response) => {
        auth.updateAuth(response.data.user, true);
      });
    },
  },

  beforeUnmount() {
    Echo.private("App.Models.User." + auth.user.id).stopListening(
      ".Illuminate\\Notifications\\Events\\BroadcastNotificationCreated"
    );
  },
};
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
}

.md-menu-content-container {
  overflow: visible;
}

.nav-bar {
  padding: 0px 12px;
}

.nav-title {
  font-weight: 600 !important;
  font-size: 16px;
  color: $primaryDark;
}

.user-menu__item {
  cursor: pointer;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.sendToFront {
  z-index: 100;
}

.style-number {
  font-weight: 600;
  font-size: 14px;
  color: $primaryDark;
}

.notification_hover_color {
  background-color: #e2daed;
}

.md-theme-default a:not(.md-button) {
  color: #c6a8ec !important;
  color: var(--md-theme-default-primary-on-background, #c6a8ec) !important;
}

.theme--light.v-list {
  background: #f3edf7 !important;
}

.theme--light.v-list-item:not(.v-list-item--active):not(
    .v-list-item--disabled
  ) {
  color: #473068 !important;
}

.theme--dark.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: #473068 !important;
}

.style_number {
  cursor: pointer;
}

.style_number:hover {
  color: #c6a8ec;
}

.notification-list-container {
  overflow-y: scroll;
  max-height: 300px;
  text-align: left;
  max-width: 450px;
}

.profile-actions-container {
  text-align: left;
}
.profile-actions-item {
  align-items: center;
  display: flex;
  font-size: 16px !important;
}
.active_color {
  background: rgba(112, 208, 206, 0.18) !important;
  color: #47c0be;
}

.inactive_color {
  background: rgba(242, 74, 74, 0.18) !important;
  color: #ff5252;
}

.completed_color {
  background: rgba(62, 1, 123, 0.18) !important;
  color: #492a67;
}

.inquiry_verified_color {
  background: #473068 !important;
  color: #ffffff;
}

.inquiry_unverified_color {
  background: #ea7a66 !important;
  color: #ffffff;
}

.md-icon {
  color: #473068 !important;
}

.notification-msg {
  border-bottom: 1px solid #492a67;
}

.notification-list-header {
  display: flex;
  text-align: right;
  justify-content: right;
  align-items: center;
}

.read-status-btn {
  width: 130px;
  height: 30px !important;
  font-size: 11px;
  border-radius: 5px;
  background-color: #5f4c7c !important;
  margin: 15px 15px !important;
  box-shadow: none;
}

.read-class {
  background: #fff !important;
}

.unread-class {
  background: #f3edf7 !important;
}

.request-verification-label {
  font-size: 16px;
  text-decoration: underline;
  cursor: pointer;
}

.request-verification-label:hover {
  color: #c6a8ec;
}

.hover-container {
  max-width: 200px;
  word-wrap: anywhere;
}

.verification-status-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.v-menu__content {
  z-index: 102 !important;
}
</style>
